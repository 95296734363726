const MLogisticsManager = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'lo.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-objects-column',
    active_icon: 'fad fa-objects-column'
  },
  {
    title: 'Deliveries',
    is_heading: true,
    is_active: false,
    link: {
      name: 'lo.deliveries'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Scheduling',
    is_heading: false,
    is_active: false,
    link: {
      name: 'lo.deliveries.scheduling'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-truck-clock',
    active_icon: 'fad fa-truck-clock'
  },
  {
    title: 'Dispatching',
    is_heading: false,
    is_active: false,
    link: {
      name: 'lo.deliveries.dispatching'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-truck-arrow-right',
    active_icon: 'fad fa-truck-arrow-right'
  },
  {
    title: 'Receiving',
    is_heading: false,
    is_active: false,
    link: {
      name: 'lo.deliveries.receiving'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-truck-fast',
    active_icon: 'fad fa-truck-fast'
  },
  {
    title: 'Closing',
    is_heading: false,
    is_active: false,
    link: {
      name: 'lo.deliveries.closing'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-box-circle-check',
    active_icon: 'fad fa-box-circle-check'
  },
  {
    title: 'Maintenances',
    is_heading: true,
    is_active: false,
    link: {
      name: 'lo.maintenances'
    },
    class_name: 'mt-3 font-weight-bolder'
  },
  {
    title: 'Vehicles',
    is_heading: false,
    is_active: false,
    link: {
      name: 'lo.maintenances.vehicles'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-truck',
    active_icon: 'fad fa-truck'
  },
  {
    title: 'Drivers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'lo.maintenances.drivers'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'fat fa-user-vneck-hair',
    active_icon: 'fad fa-user-vneck-hair'
  }
]

export default MLogisticsManager
