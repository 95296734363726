import MAdmin from './admin.menu'
import MLogisticsManager from './lm.menu'
import MLogisticsOfficer from './lo.menu'
import MUser from './user.menu'

export const getSidebarMenus = user => {
  switch (user.role) {
    case 'admin': {
      return MAdmin
    }
    case 'lm': {
      return MLogisticsManager
    }
    case 'lo': {
      return MLogisticsOfficer
    }
    case 'user': {
      return MUser.filter(
        ({ link }) => {
          const cannotStockOut = Number(user?.allow_out) === 0 && ![
            'user.purchase-orders.approvals',
            'user.sap',
            'user.sap.posting'
          ].includes(link?.name)

          const cannotStockIn = Number(user?.allow_in) === 0 && ![
            'user.purchase-orders.requests',
            'user.purchase-orders.receiving'
          ].includes(link?.name)

          return link && (cannotStockIn || cannotStockOut)
        }
      )
    }
    default: {
      return []
    }
  }
}
